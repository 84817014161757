<template>
  <!--begin::Navbar-->
  <div class="card mb-5 mb-xxl-8">
    <div class="card-body pt-9 pb-0">
      <!--begin::Details-->
      <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
        <!--begin: Pic-->
        <div class="me-7 mb-4">
          <div
            class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
          >
            <img
              :src="dto.avatar ? dto.avatar : '/media/svg/avatars/blank.svg'"
              alt="image"
            />
          </div>
        </div>
        <!--end::Pic-->

        <!--begin::Info-->
        <div class="flex-grow-1">
          <!--begin::Title-->
          <div
            class="d-flex justify-content-between align-items-start flex-wrap mb-2"
          >
            <!--begin::User-->
            <div class="d-flex flex-column">
              <!--begin::Name-->
              <div class="d-flex align-items-center mb-2">
                <div
                  style="cursor: pointer"
                  class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                >
                  {{ dto.name }}
                </div>
                <!--<a href="#">
                  <span class="svg-icon svg-icon-1 svg-icon-primary">
                    <inline-svg src="/media/icons/duotune/general/gen026.svg" />
                  </span>
                </a>-->
              </div>
              <!--end::Name-->

              <!--begin::Info-->
              <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                <div
                  style="cursor: pointer"
                  class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg
                      src="/media/icons/duotune/communication/com006.svg"
                    />
                  </span>
                  {{ getTitle() }}
                </div>
                <a
                  v-if="dto.fields.site"
                  :href="dto.fields.site"
                  class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg
                      src="/media/icons/duotune/communication/com012.svg"
                    />
                  </span>
                  {{ decorateSite(dto.fields.site) }}
                </a>

                <a
                    v-if="dto.fields.discord"
                    :href="dto.fields.discord"
                    class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg
                        src="/media/icons/duotune/communication/com013.svg"
                    />
                  </span>
                  Discord
                </a>

                <a
                  v-if="dto.fields.youtube"
                  :href="dto.fields.youtube"
                  class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                >
                  <span class="svg-icon svg-icon-4 me-1">
                    <inline-svg src="/media/icons/duotune/social/soc007.svg" />
                  </span>
                  {{ dto.name }}
                </a>
              </div>
              <!--end::Info-->
            </div>
            <!--end::User-->

            <!--begin::Actions-->
            <div class="d-flex my-4">
              <div
                class="btn btn-sm btn-light me-2"
                id="kt_user_follow_button"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_its_me"
                v-if="!dto.avatar"
              >
                <span class="svg-icon svg-icon-3 d-none">
                  <inline-svg src="/media/icons/duotune/arrows/arr012.svg" />
                </span>
                {{ $t("thatsme") }}
              </div>
            </div>
            <!--end::Actions-->
          </div>
          <!--end::Title-->

          <!--begin::Stats-->
          <div class="d-flex flex-wrap flex-stack">
            <!--begin::Wrapper-->
            <div class="d-flex flex-column flex-grow-1 pe-8">
              <!--begin::Stats-->
              <div class="d-flex flex-wrap">
                <span>{{ dto.description }}</span>
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Stats-->
        </div>
        <!--end::Info-->
      </div>
    </div>
  </div>
  <ModsView :mods="dto.mods"></ModsView>
  <div
    class="modal fade"
    id="kt_modal_its_me"
    ref="its_me_modal_ref"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header" id="kt_modal_create_api_key_header">
          <!--begin::Modal title-->
          <h2>{{ $t("change_author_information")}}</h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>

        <!--begin::Modal body-->
        <div class="modal-body">
          <div class="fs-5">
            {{ $t('ifauthor') }}
            <ul>
              <li>{{ $t("avatar") }}</li>
              <li>{{ $t("characteristic") }}</li>
              <li>{{ $t('website') }}</li>
              <li>{{ $t('youtube_channel')}}</li>
              <li>Discord</li>
            </ul>
            {{ $t('reach_us')}}
            <a href="https://www.serioussite.ru/index/8-1330" class="fw-bold"
              >{{ $t('serioussite')}}</a
            >,
            <a class="fw-bold" href="https://discord.com/invite/bm8Awq5">Discord.</a>
          </div>
        </div>
        <!--end::Modal body-->

        <!--begin::Modal footer-->
        <div class="modal-footer flex-center">
          <!--begin::Button-->
          <button
            ref="submitButtonRef"
            type="submit"
            id="kt_modal_create_api_key_submit"
            class="btn btn-primary"
            data-bs-dismiss="modal"
          >
            <span class="indicator-label"> Ok </span>
          </button>
          <!--end::Button-->
        </div>
        <!--end::Modal footer-->
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ModsView from "@/components/ModsView";
import ApiService from "../core/services/ApiService";

export default defineComponent({
  name: "author",
  components: {
    ModsView,
  },
  data: function () {
    return {
      dto: {
        name: "",
        description: "",
        fields: {},
        avatar: "",
        mods: [],
      },
    };
  },

  created: async function () {
    await ApiService.get("api", `author/${this.$route.params.id}?lang=ru`)
      .then((response) => {
        this.dto = response.data;
        document.title = this.dto.name + " - Gro Repository";
        this.dto.fields = JSON.parse(this.dto.fields);
      })
      .catch(() => {
        this.$router.push({ name: "404" });
      });
  },

  methods: {
    decorateSite: function (site) {
      site = site.replace("https://", "").replace("http://", "");
      if (site.charAt(site.length - 1) === "/") {
        site = site.substr(0, site.length - 1);
      }
      return site;
    },

    getTitle: function() {
      if (this.dto.id == null)
        return "";

      if (this.dto.mods.find((item) => item.type == 1)) {
        return this.$t("modmaker");
      }

      return this.$t("mapper");
    },
  },
});
</script>
